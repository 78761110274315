* {
    padding: 0;
    margin: 0;
    box-shadow: none;
}

/* General header styling */
.header {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-top: 70px; 
}

/* Name styling for the fixed top navigation */
.name {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    font-family: "Sevillana", cursive;
    font-size: 2.5em;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: red;
    width: 100%;
    z-index: 1000;
    padding: 10px;
    box-sizing: border-box; 
}

/* Opening times font styling */
.opening {
    font-size: 0.5em;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* Intro section */
.intro {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding: 20px;
    margin-top: 70px; 
}

/* Sample image within the intro section */
#sample-1 {
    height: 45vh;
    width: auto;
    border-radius: 8px;
}

.main {
    font-size: 1.5em;
}

/* Unordered list reset */
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

/* Background container for the intro */
.intro-bg {
    position: relative;
    width: 100%;
    max-width: 100%; 
    text-align: center; 
}

/* Responsive image scaling */
#intro-bg-src {
    width: 100%;
    height: auto; /* Maintains aspect ratio */
}

/* Intro sentence overlaying the background */
.intro-sentence {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem; /* Adjust as needed */
    font-weight: bold;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Shadow for readability */
}

.intro-sentence p {
    margin: 0;
    font-size: 1rem;
}

/* Book Now button styling */
.book-now {
    position: absolute;
    padding: 10px 20px;
    font-size: 1.2rem;
    background-color: #e91e63;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    bottom: 15%; 
    left: 50%;
    transform: translateX(-50%);
    width: 80%; 
    max-width: 150px; 
}

.book-now:hover {
    background-color: #c2185b;
}

/* Tablet responsiveness */
@media (max-width: 768px) {
    .header {
        padding: 5px;
    }

    .name {
        font-size: 2em;
    }

    .intro {
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }

    #sample-1 {
        height: 35vh;
    }

    .main {
        font-size: 1.2em;
    }

    .intro-sentence {
        font-size: 1.75em; 
    }

    .book-now {
        font-size: 0.8em; 
        padding: 8px; 
        bottom: 10%; 
    }
}

/* Mobile device responsiveness */
@media (max-width: 480px) {
    .header {
        padding: 3px;
    }

    .name {
        font-size: 1.5em;
    }

    .intro {
        padding: 10px;
        margin-top: 40px;
    }

    #sample-1 {
        height: 30vh;
    }

    .main {
        font-size: 1em;
    }

    .intro-sentence {
        font-size: 1.5em;
    }

    .book-now {
        font-size: 0.75em;
        padding: 6px;
        bottom: 7.5%;
    }
}