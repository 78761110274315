.admin-dashboard {
    padding: 20px;
    background-color: #f9f9f9;
}

h1 {
    text-align: center;
    margin-bottom: 20px; 
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 20px; 
    text-align: left;
}

th {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

td[colspan="5"] {
    text-align: center;
    padding: 20px; 
}

@media screen and (max-width:768px) {
    table {
        display: block;
        width:100%;
        overflow-x: auto;
    }
    th, td {
        padding:10px;
        font-size: 14px;
    }
    button {
        width: 100%;
        padding: 12px;
        font-size: 16px;
    }
    h1 {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    th, td {
        padding: 8px;
        font-size: 12px;
    }

    h1 {
        font-size: 18px;
    }

    button {
        padding: 10px;
        font-size: 14px;
    }

    table {
        display: block;
        overflow-x: auto;
        width: 100%;
    }
}
