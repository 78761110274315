#date, #not-found {
    font-family: RoobertPRO, sans-serif;
}

#date {
    cursor: pointer;
}

#not-found {
    display: flex;
    justify-content: center;
}

body {
    background-color: pink;
    /* background: linear-gradient(to bottom, pink, purple);     */
}

.serviceList {
    display: flex;
    flex-direction: column;
    height:100vh;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width:300px;
}

#title-book {
    display: flex;
    justify-content: center;
    align-items: center;
}

input, select {
    padding:10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.bookapp {
    padding:10px;
    border:none;
    border-radius: 50px;
    background-color: #1366be;
    color:#fff;
    cursor:pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.bookapp:hover {
    background-color: #7a9cc7;
    transform: scale(1.05);
}

.map {
    display: flex;
    justify-content: center;
    padding:10px;
}

.social {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.social div {
    margin-bottom: 10px;
}

.social img {
    width: 50px;
    height: 50px; 
    cursor: pointer;
    object-fit: cover;
}

.menu {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 equal columns */
    gap: 20px; /* Adds space between items */
    padding: 10px;
    margin: 0 auto; 
    max-width: 1200px; 
}

.menu-item {
    box-sizing: border-box;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px; 
    padding: 15px;
    text-align: left; /* Align text to the left */
    transition: background-color 0.3s, transform 0.3s; 
    display: flex;
    flex-direction: column; /* Stack the heading and container vertically */
}

.menu-item:hover {
    background-color: #e0e0e0; 
    transform: scale(1.05); 
}

.menu-item h3 {
    margin: 0 0 10px;
    font-size: 1.2em; 
}

/* Responsive design for tablets */
@media screen and (max-width: 768px) {
    .menu {
        grid-template-columns: repeat(2, 1fr); /* 2 columns for tablets */
    }
}

/* Responsive design for mobile phones */
@media screen and (max-width: 480px) {
    .menu {
        grid-template-columns: 1fr; /* 1 column for mobile */
    }
}

/* Additional styling for text inside the menu items */
#menu-service {
    margin-right: 20px; 
    font-size: 1em; 
    color: #333;
}

#menu-price {
    font-size: 1em; 
    color: #333;
    font-weight: bold; 
}

#menu-service {
    margin-right: 20px; 
    font-size: 1em; 
    color: #333;
}

#menu-price {
    font-size: 1em; 
    color: #333;
    font-weight: bold; 
}

.verify-code-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    width: 300px;
    margin: 0 auto; 
}

.verify-code-box input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
}

.verify-code-box button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #92b1d3;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.verify-code-box button:hover {
    background-color: #7a9cc7;
    transform: scale(1.05);
}

